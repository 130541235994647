import "./App.css";
import Navbar from "./components/globalComponent/Navbar";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";

import Auth from "./pages/Auth";
import Logout from "./pages/Logout";
import Registrazione from "./pages/Registrazione";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authCheck } from "./store/actions/handelAuth";
import PDFView from "./pages/Pdfview";

function App() {
  const userToken = useSelector((state) => state.authReducer.token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);

  return (
    <>
      {/* {userToken ? <Navbar isAutenticated={userToken} /> : null} */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/admin/pdf" component={PDFView} />
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Route path="/logout" component={Logout} />
        {/* <Route path="/registrazione" component={Registrazione} /> */}
        {/* <Route
          path="/payment"
          component={() => {
            window.location.href = "https://buy.stripe.com/bIYfZb68FeeGgSIeUX";
            return null;
          }}
        /> */}
      </Switch>
      {/* </Online> */}
    </>
  );
}

export default App;
