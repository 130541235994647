import * as actionTypes from "../actions/handleHome";

const initialState = {
  loading: false,
  error: false,
  message: "",
  success: false,
  arrayListSoci: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HOME_FUNCTION_START:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actionTypes.HOME_FUNCTION_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        //
        loading: false,
        error: false,
        success: true,
      };
    case actionTypes.HOME_FUNCTION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: true,
        message: action.message,
      };

    case actionTypes.SEARC_SOCI_START:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actionTypes.SEARC_SOCI_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        arrayListSoci: action.arrayListSoci,
        //
        loading: false,
        error: false,
        success: false,
      };
    case actionTypes.SEARC_SOCI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: action.message,
      };
    case actionTypes.FETCH_DATA_SELECTED_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_DATA_SELECTED_SUCCESS:
      return {
        ...state,
        //action
        arrayListSoci: action.arrayListSoci,
        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_DATA_SELECTED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.ERROR_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
      };

    default:
      return state;
  }
};

export default reducer;
