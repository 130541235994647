import React from "react";

function SocioCard({ socio }) {
  return (
    <tr className="row-ts">
      <th scope="row">{socio.nTessera}</th>
      <td>{socio.name.toUpperCase()}</td>
      <td>{socio.surname.toUpperCase()}</td>

      <td>{socio.idCard.toUpperCase()}</td>

      <td>{socio.dataRicheista}</td>

      <td>{socio.telefono}</td>
    </tr>
  );
}

export default SocioCard;
