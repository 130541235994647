import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutRoot } from "../store/actions/handelAuth";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutRoot());
  });

  return <Redirect to="/" />;
};
export default Logout;
