import { doc, collection, getDocs, query } from "@firebase/firestore";
import db from "../Firebase-config";

export const searchListSoci = async () => {
  let arrayListSoci = [];
  // riferimento univco del magazzino Utente
  const email = localStorage.getItem("email");
  const refMag = email.split("@");
  const addRefRubrica = doc(db, `${refMag[0]}.db`, "soci"); // riferimento del db nello rubrica

  const q = query(collection(addRefRubrica, "rubrica"));
  const querySnapshot = await getDocs(q);
  const position = querySnapshot.docs;

  position.forEach((doc) => {
    arrayListSoci.push({
      name: doc._document.data.value.mapValue.fields.name.stringValue,
      surname: doc._document.data.value.mapValue.fields.surname.stringValue,

      idCard: doc._document.data.value.mapValue.fields.idCard.stringValue,

      dataRicheista: doc._document.data.value.mapValue.fields.dataRicheista.stringValue,

      telefono: doc._document.data.value.mapValue.fields.telefono.stringValue,
      nTessera: doc._document.data.value.mapValue.fields.nTessera.integerValue,
    });
  });

  return arrayListSoci;
};
