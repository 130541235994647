import React from "react";
import { useSelector } from "react-redux";
import CreatePdf from "../components/adminComponent/CreatePdf";

const PdfViewer = (params) => {
  const arrayListSoci = useSelector((state) => state.homeReducers.arrayListSoci);
  const dal = params.location.state.dal;
  const al = params.location.state.al;

  return (
    <div className="App">
      <CreatePdf arrayListSoci={arrayListSoci} dal={dal} al={al} />
    </div>
  );
};
export default PdfViewer;
