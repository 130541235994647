import { useState } from "react";
import "../styles/Auth.css";
import { useSelector, useDispatch } from "react-redux";
import { auth, resetPassword } from "../store/actions/handelAuth";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Auth = () => {
  const [email, setmail] = useState("");
  const [password, setpassword] = useState("");

  const userToken = useSelector((state) => state.authReducer.token);
  const error = useSelector((state) => state.authReducer.error);

  const dispatch = useDispatch();

  const handleEmail = (e) => {
    setmail(e.target.value);
  };
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(auth(email, password, false));
    setpassword("");
    setpassword("");
  };

  let shouldRedirect = null;
  if (userToken) {
    shouldRedirect = <Redirect to="/admin" />;
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const { value: text } = await Swal.fire({
      title: "Hai dimenticato la Password ?",
      input: "text",
      inputLabel:
        "inserisci qui la tua mail ed invieremo un link con il quale potrai resettarla",
      inputPlaceholder: "inserisci qui la mail",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    });
    if (text) {
      dispatch(resetPassword(text));
    }
  };

  return (
    <div className="container-auth">
      {shouldRedirect}
      <form action="">
        <p>Email</p>
        <input
          type="email"
          value={email}
          onChange={handleEmail}
          placeholder="scrivi la tua mail"
        />
        <p>Password</p>
        <input
          type="password"
          value={password}
          onChange={handlePassword}
          placeholder="scrivi la tua password"
        />
        <button className="auth-btn" onClick={handleSubmit}>
          LogIn
        </button>
        {/* <Link
          to={{
            pathname: `/payment`,
          }}
        >
          <button className="btn-registrazione">Registrati</button>{" "}
        </Link> */}

        <button className="pass-dimenticata" onClick={handleResetPassword}>
          Password dimenticata ?
        </button>
        <p className="auth-error">{error}</p>
      </form>

      {/* <button onclick={handleSubmit}>LOGIN</button> */}
    </div>
  );
};
export default Auth;
