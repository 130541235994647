import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "../styles/Home.css";
import { useDispatch, useSelector } from "react-redux";
import { errorReset, homeFunction } from "../store/actions/handleHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stateChange } from "../functions/stateChange";
import { checkMessage } from "../functions/Message";
import { CeckConnection } from "../functions/ConnectionStatius";
import soudError from "../sound/error.wav";
import * as FiIcons from "react-icons/fi";

registerLocale("it", it);
function Home() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // const [startDate, setStartDate] = useState(new Date());
  // const [isOpen, setIsOpen] = useState(false);
  // const [daySelected, setDaySelected] = useState(
  //   startDate.toLocaleDateString().replaceAll("/", "-")
  // );
  const error = useSelector((state) => state.homeReducers.error);
  const message = useSelector((state) => state.homeReducers.message);
  const loading = useSelector((state) => state.homeReducers.loading);
  const success = useSelector((state) => state.homeReducers.success);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    cf: "",
    dataN: "",
    idCard: "",
    scadIdCard: "",
    paeseDiNascita: "",
    mail: "",
    telefono: "",
    invalidCheck1: false,
    invalidCheck2: false,
  });

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };
  // ----------------------------------------------------------------

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------

  const notifySuccess = () =>
    toast.success("Richeista effettuata con Successo !!", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______
    // if (token) {
    stateChange();
    dispatch(errorReset());
    handleMessage();
    // }
  }, [dispatch]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  // if (!token) {
  //   return <Redirect to="/auth"></Redirect>;
  // }

  // controlla se ci sono messaggi da far apparire a tutti gli utenti dopo  essersi loggati
  const handleMessage = async () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //-----
    const res = await checkMessage();
    if (res.display === true) {
      Swal.fire({
        title: res.title,
        icon: "info",
        html: res.message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  };
  function haveSC(str) {
    return !/[~`.!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }
  function haveSCForData(str) {
    return !/[~`!#@$%\^&*+=\\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }
  function hasNumber(myString) {
    return /\d/.test(myString);
  }
  function hasMail(str) {
    return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }
  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    if (
      formData.name.trim() === "" ||
      !haveSC(formData.name) ||
      hasNumber(formData.name)
    ) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'nome' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }

    if (
      formData.surname.trim() === "" ||
      !haveSC(formData.surname) ||
      hasNumber(formData.surname)
    ) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Cognome' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }

    if (formData.idCard.trim() === "" || !haveSC(formData.idCard)) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Carta di Identità' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }

    if (
      formData.telefono.trim() === "" ||
      !haveSC(formData.telefono) ||
      !hasNumber(formData.telefono) ||
      formData.telefono.length !== 10
    ) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Cellulare' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (!formData.invalidCheck1 || !formData.invalidCheck2) {
      Swal.fire({
        title: "Errore",
        text: "Prima di procedere devi confermare la lettura ed autorizzare la trattamento dei dati",
        icon: "warning",
      });
      return;
    }
    dispatch(homeFunction(formData));

    setFormData({
      name: "",
      surname: "",
      idCard: "",
      telefono: "",
      invalidCheck1: false,
      invalidCheck2: false,
    });
  };

  return (
    <>
      <div className="head-bar-ts">
        {token ? (
          <div className="" style={{ zIndex: 5 }}>
            <Link to="/admin">Admin</Link>
            <span>
              <Link to="/logout" className="">
                <FiIcons.FiLogOut />
              </Link>
            </span>
          </div>
        ) : (
          <Link
            to={{
              pathname: `/auth`,
            }}
          >
            Login
          </Link>
        )}
      </div>

      <div className="home">
        <div className="home-container">
          <img
            src={"https://i.ibb.co/jVFTc7B/Monkey-Pub-LOGO-PNG.png"}
            alt=""
            class="img-fluid img-ts"
          />

          <div className="form-container-ts">
            <div class="form-group mt-3">
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                placeholder="Nome"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required=""
              />
            </div>
            <div class="form-group mt-3">
              <input
                id="surname"
                name="surname"
                type="text"
                class="form-control"
                placeholder="Cognome"
                value={formData.surname}
                onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
              />
            </div>

            <div class="form-group mt-3">
              <input
                id="idCard"
                name="idCard"
                type="text"
                class="form-control"
                placeholder="Numero Doc di Riconoscimento"
                value={formData.idCard}
                onChange={(e) => setFormData({ ...formData, idCard: e.target.value })}
              />
            </div>

            <div class="form-group mt-3">
              <input
                id="telefono"
                name="telefono"
                type="text"
                class="form-control"
                placeholder="Cellulare"
                value={formData.telefono}
                onChange={(e) => setFormData({ ...formData, telefono: e.target.value })}
              />
            </div>
          </div>
          <p className="p-chiede">Chiede</p>
          <div className="regolamento">
            <p className="">
              Di essere ammesso come socio. A tale scopo dichiaro di condividere gli
              obbiettivi espressi nello statuto e di voler contribuire alla loro
              realizzazione. Il sottoscritto inoltre:
            </p>
            <p className="p-text">
              a) Si impegna nell’osservanza delle norme statuarie, del regolamento interno
              e delle disposizioni del consiglio direttivo;
            </p>
            <p className="p-text">
              b) Prende atto che l’adesione è subordinata all’accettazione della domanda a
              parte dell’organo statuario previsto dallo statuto;
            </p>
            <p className="p-text">
              c) Qual'ora fosse minorenne dichiara sotto la sua responsabilità di essere
              autorizzato da un genitore o chi ne fa le veci
            </p>
            <p className="p-text">
              d) In quanto socio avrà il diritto di essere iscritto nel libro dei soci e a
              partecipare alle attività associative e alle assemblee, ad eleggere cariche
              sociale ed a essere eletto.
            </p>
            <p className="p-text">
              e) Da il consenso ad essere ripreso del sistema di video sorveglianza a
              scopo di sicurezza
            </p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck1"
                name="invalidCheck1"
                checked={formData.invalidCheck1}
                onChange={() =>
                  setFormData({ ...formData, invalidCheck1: !formData.invalidCheck1 })
                }
                required
              />
              <label class="form-check-label" for="invalidCheck1xw">
                Conferma la lettura dello statuto
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck2"
                name="invalidCheck2"
                checked={formData.invalidCheck2}
                onChange={() =>
                  setFormData({ ...formData, invalidCheck2: !formData.invalidCheck2 })
                }
                required
              />
              <label class="form-check-label" for="invalidCheck2">
                Conferma il trattamento dei dati personali
              </label>
            </div>
            <p class="text-sm-start ts-text">
              PRIVACY - CONSENSO AL TRATTAMENTO DATI PERSONALI(Regolamento Europeo
              679/2016 ) -{" "}
              <a href="https://www.vespaseason.it/informativaprivacy.pdf">visualizza</a>,
              se lo desideri, il documento"PRIVACY COOKIE" presente all'interno della
              pagina ove ti trovi al momento
            </p>

            <div class="col-12">
              <button class="btn btn-primary" type="submit" onClick={submitForm}>
                Invia richiesta
              </button>
            </div>
          </div>
        </div>
        {error ? (playError(), notifyError(), alterShow()) : null}
        {success ? (notifySuccess(), alterShow()) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default Home;
