import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
// Create styles
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
    margin: "2 2 2 2",
  },
  sectionHead: {
    margin: "0 0 0 0",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionBody: {
    margin: 2,
    padding: 1,
    display: "flex",
    flexDirection: "colum",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  sectionData: {
    marginTop: 2,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    margin: 4,
    fontSize: 20,
    textAlign: "center",
    // width: ,
    marginLeft: 2,
  },
  head: {
    margin: 4,
    fontSize: 15,
    width: 500,
    marginLeft: 2,
  },
  footer: {
    margin: 4,
    fontSize: 10,
    textAlign: "justify",
    width: 300,
    marginLeft: 40,
  },
  textGiacenze: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  textVmg: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 70,
  },
  textPo: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  divClass: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

// Create Document Component
function CreatePdf({ arrayListSoci, dal, al }) {
  const da = arrayListSoci[0].nTessera;
  const a = arrayListSoci[arrayListSoci.length - 1].nTessera;

  let dateArr = al.split("-");
  const dataAl = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
  const days = 86400000; //number of milliseconds in a day

  const dataAssemblea = dataAl.addDays(5).toLocaleDateString().replaceAll("/", "-");

  const ggMMaaaa = dataAssemblea.split("-");
  const gg = ggMMaaaa[0];
  const mm = ggMMaaaa[1];
  const aaaa = ggMMaaaa[2];
  const month = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const mmT = month[mm - 1];

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>VERBALE N. DEL CONSIGLIO DIRETTIVO</Text>
          </View>

          <View style={styles.sectionHead}>
            <Text style={styles.head}>
              L’anno {aaaa} il giorno {gg} del mese {mmT} alle ore 10:30 e seguenti,
              presso la sede in Piazza Vittorio Emanuele III° n.8 , Acquedolci (ME), su
              invito del presidente, così come previsto dallo Statuto, si è riunito il
              Consiglio Direttivo dell’Associazione “ Club Montecarlo ”, per analizzare,
              discutere e deliberare sul seguente ordine del giorno:
            </Text>
            <Text style={styles.head}>1) Ammissione nuovi soci</Text>
            <Text style={styles.head}>2) Varie ed eventuali</Text>
            <Text style={styles.head}>
              Nel luogo e nell’ora indicata risultano presenti i signori:
            </Text>
            <Text style={styles.head}>- Presidente: Carroccetto Luigi </Text>
            <Text style={styles.head}>- Vice Presidente: Caruso Antonino</Text>
            <Text style={styles.head}>- Segretario : Lorello Salvatore</Text>
            <Text style={styles.head}>- Consigliere: Schilirò Grazia </Text>
            <Text style={styles.head}>
              Assume la presidenza il sig. Carroccetto Luigi il quale, constatata la
              presenza di tutti i componenti, il Consiglio Direttivo in carica, dichiara
              la seduta validamente costituita e chiama a fungere da segretario il signor
              Lorello Salvatore presente che accetta. Il Presidente prende la parola per
              illustrare il primo punto all’ordine del giorno. In particolare precisa che
              dal {dal} al {al} sono pervenute n. {arrayListSoci.length} domande di
              ammissione a socio ordinario e che i suddetti signori sono in possesso dei
              requisiti previsti dallo Statuto dell’Associazione. Il Consiglio direttivo,
              dopo aver ascoltato il Presidente ad aver verificato le domande di
              ammissione,
            </Text>
            <Text style={styles.head}>DELIBERA</Text>
            <Text style={styles.head}>
              1) di accettare in qualità di soci i suddetti signori, già iscritti nel
              libro dei soci ai seguenti numeri da n. {da} a n. {a}
            </Text>
            <Text style={styles.head}>
              Si puntualizza, inoltre, che i soci della Associazione Ricreativa “ Al
              Novantesimo ” verranno accettati, quali soci ordinari dell’Associazione, a
              seguito di relativa delibera da parte del Consiglio Direttivo e consegna
              della tessera dell’Associazione, acquisendo così, il libero accesso solo in
              questa sede. Nessun altro avendo preso la parola sul 2° punto posto
              all’ordine del giorno, la riunione è sciolta alle ore 11.00 previa stesura
              ed approvazione del presente verbale.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default CreatePdf;
