import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDIjWDH0VbyG4xsAF5oeaeWDPRtNT0qFLQ",
  authDomain: "tesseramento-soci-test.firebaseapp.com",
  projectId: "tesseramento-soci-test",
  storageBucket: "tesseramento-soci-test.appspot.com",
  messagingSenderId: "1020544946467",
  appId: "1:1020544946467:web:615564ce6b62fec74efc96",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
  console.log("persistance error", err.code);
});

export default db;
