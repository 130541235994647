import db from "../../Firebase-config";
import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
  query,
} from "@firebase/firestore";
import { searchListSoci } from "../../functions/searchListSoci";
import { registerLocale } from "react-datepicker";
import "fix-date";

const HOME_FUNCTION_START = "HOME_FUNCTION_START";
const HOME_FUNCTION_SUCCESS = "HOME_FUNCTION_SUCCESS";
const HOME_FUNCTION_FAIL = "HOME_FUNCTION_FAIL";

const SEARC_SOCI_START = "SEARC_SOCI_START";
const SEARC_SOCI_SUCCESS = "SEARC_SOCI_SUCCESS";
const SEARC_SOCI_FAIL = "SEARC_SOCI_FAIL";

const FETCH_DATA_SELECTED_START = "FETCH_DATA_SELECTED_START";
const FETCH_DATA_SELECTED_SUCCESS = "FETCH_DATA_SELECTED_SUCCESS";
const FETCH_DATA_SELECTED_FAIL = "FETCH_DATA_SELECTED_FAIL";

const ERROR_RESET = "ERROR_RESET";

export const homeFunction = (formsData) => {
  return async (dispatch, getState) => {
    dispatch(homeFunctionStart());
    try {
      const dataRicheista = new Date().toLocaleDateString().replaceAll("/", "-");
      const addRefRubrica = doc(db, "blattastadium.db", "soci"); // riferimento del db nello rubrica
      const addRefContatore = doc(db, "blattastadium.db", "contatore"); // riferimento del db nello rubrica
      const addRefNomi = doc(addRefRubrica, "rubrica", formsData.telefono.toLowerCase()); // singolo socio

      // creo il riferimento per la rubrica
      let dataCount = await getDoc(addRefContatore);
      const contatore =
        Number(dataCount._document.data.value.mapValue.fields.contatore.integerValue) + 1;

      let dataRubrica = await getDoc(addRefNomi);
      const rubricaExist = dataRubrica.exists();
      if (rubricaExist === false) {
        //creo un nuovo documento nella rubrica che sarebbe il primo
        setDoc(addRefNomi, {
          name: formsData.name.toLowerCase(),
          surname: formsData.surname.toLowerCase(),
          idCard: formsData.idCard.toLowerCase(),
          telefono: formsData.telefono,
          dataRicheista: dataRicheista,
          nTessera: contatore,
        });
        updateDoc(addRefContatore, { contatore });
        dispatch(homeFunctionSuccess());
      } else {
        dispatch(homeFunctionFail(true, "nome gia esistente"));
      }
    } catch (error) {
      dispatch(homeFunctionFail(true, error));
    }
  };
};

export const homeFunctionStart = () => {
  return {
    type: HOME_FUNCTION_START,
  };
};

export const homeFunctionSuccess = () => {
  return {
    type: HOME_FUNCTION_SUCCESS,

    //action
  };
};

export const homeFunctionFail = (error, message) => {
  return {
    type: HOME_FUNCTION_FAIL,
    error: error,
    message: message,
  };
};
export const searchSoci = () => {
  return async (dispatch, getState) => {
    dispatch(searchSociStart());
    try {
      const arrayListSoci = await searchListSoci();

      dispatch(searchSociSuccess(arrayListSoci));
    } catch (error) {
      dispatch(searchSociFail(true, error));
    }
  };
};

export const searchSociStart = () => {
  return {
    type: SEARC_SOCI_START,
  };
};

export const searchSociSuccess = (arrayListSoci) => {
  return {
    type: SEARC_SOCI_SUCCESS,
    arrayListSoci,

    //action
  };
};

export const searchSociFail = (error, message) => {
  return {
    type: SEARC_SOCI_FAIL,
    error: error,
    message: message,
  };
};
export const fetchDataSelected = (dal, al) => {
  return async (dispatch) => {
    dispatch(fetchDataSelectedStart());
    try {
      let dateArr = dal.split("-");
      let mese = dateArr[1];
      if (dateArr[1].charAt(0) === "0") {
        mese = dateArr[1].charAt(1);
      }
      const dataDal = new Date(`${dateArr[2]}-${mese}-${dateArr[0]}`);

      let dateArr2 = al.split("-");
      mese = dateArr2[1];
      if (dateArr2[1].charAt(0) === "0") {
        mese = dateArr2[1].charAt(1);
      }
      const dataAl = new Date(`${dateArr2[2]}-${mese}-${dateArr2[0]}`);

      const arrayListSoci = (await searchListSoci()).filter((item) => {
        let dateArr = item.dataRicheista.split("-");
        let dataTempItem = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);

        if (
          dataTempItem.getTime() <= dataAl.getTime() &&
          dataTempItem.getTime() >= dataDal.getTime()
        ) {
          return item;
        }
      });

      // end
      dispatch(fetchDataSelectedSuccess(arrayListSoci));
    } catch (error) {
      dispatch(fetchDataSelectedFail(error));
      console.log(error);
    }
  };
};

export const fetchDataSelectedStart = () => {
  return {
    type: FETCH_DATA_SELECTED_START,
  };
};

export const fetchDataSelectedSuccess = (arrayListSoci) => {
  return {
    type: FETCH_DATA_SELECTED_SUCCESS,
    arrayListSoci,
  };
};

export const fetchDataSelectedFail = (error) => {
  return {
    type: FETCH_DATA_SELECTED_FAIL,
    error: error,
  };
};

export const errorReset = () => {
  return {
    type: ERROR_RESET,
  };
};

export {
  HOME_FUNCTION_START,
  HOME_FUNCTION_SUCCESS,
  HOME_FUNCTION_FAIL,
  ERROR_RESET,
  SEARC_SOCI_START,
  SEARC_SOCI_SUCCESS,
  SEARC_SOCI_FAIL,
  FETCH_DATA_SELECTED_START,
  FETCH_DATA_SELECTED_SUCCESS,
  FETCH_DATA_SELECTED_FAIL,
};
