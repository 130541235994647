import { combineReducers } from "redux";

import homeReducers from "./homeReducer";
import authReducer from "./authReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  homeReducers,
  authReducer,
  dataReducer,
});

export default rootReducer;
