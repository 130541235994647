import { doc, getDoc } from "@firebase/firestore";

import db from "../Firebase-config";

export const checkMessage = async () => {
  const refMessage = doc(db, `message`, "message"); // riferimento del db del message
  let data = await getDoc(refMessage);
  let display = data._document.data.value.mapValue.fields.display.booleanValue;
  let title = data._document.data.value.mapValue.fields.title.stringValue;
  let message = data._document.data.value.mapValue.fields.text.stringValue;

  return { display: display, message: message, title: title };
};
