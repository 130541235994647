import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidbarData";
import { SidebarDataMini } from "./SidbarDataMini";
import "./Navbar.css";
import { IconContext } from "react-icons";
import ReactTooltip from "react-tooltip";

function Navbar({ isAutenticated }) {
  const email = localStorage.getItem("email");
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          {isAutenticated ? (
            <div className="menu-bars" style={{ zIndex: 5 }}>
              <p>{email} </p>
              <span>
                <Link to="/logout" className="menu-bars">
                  <FiIcons.FiLogOut style={{ fontSize: "27px" }} />
                </Link>
              </span>
            </div>
          ) : (
            <Link to="/auth" className="menu-bars">
              <p>Accedi</p> <FiIcons.FiLogIn />
            </Link>
          )}
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <Link to="#" className={sidebar ? "nav-text-mini active" : "nav-text-mini"}>
              <span>
                {sidebar ? (
                  <AiIcons.AiOutlineClose onClick={showSidebar} />
                ) : (
                  <FaIcons.FaBars onClick={showSidebar} />
                )}
              </span>
            </Link>
            <li className="navbar-toggle"></li>
            {/* mappa il sidebardata.js che contiene un oggetto con tutte le voci del menu  */}

            {sidebar ? (
              SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path} onClick={showSidebar}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })
            ) : (
              <div>
                {SidebarDataMini.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path} data-tip={item.title} data-delay-show="300">
                        {item.icon}
                      </Link>
                      <ReactTooltip multiline={true} />
                    </li>
                  );
                })}
              </div>
            )}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
