import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { stateChange } from "../functions/stateChange";
import "../styles/Admin.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import DataPikers from "../components/globalComponent/DataPikers";
import { Link } from "react-router-dom";
import SocioCard from "../components/adminComponent/SocioCard";
import { errorReset, fetchDataSelected, searchSoci } from "../store/actions/handleHome";

function Admin() {
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const dispatch = useDispatch();
  const arrayListSoci = useSelector((state) => state.homeReducers.arrayListSoci);
  const [input, setInput] = useState("");
  const [inputDal, setInputDal] = useState(
    new Date().toLocaleDateString().replaceAll("/", "-")
  );
  const [inputAl, setInputAl] = useState(
    new Date().toLocaleDateString().replaceAll("/", "-")
  );
  const [check, setCheck] = useState(false);
  const startDate = useSelector((state) => state.dataReducer.startDate);

  useEffect(() => {
    if (token && email === "blattastadium@gmail.com") {
      stateChange();
      dispatch(errorReset());
      // dispatch(searchSoci());
    }
  }, [dispatch, email, token, startDate]);

  if (!token || email !== "blattastadium@gmail.com") {
    return <Redirect to="/"></Redirect>;
  }

  const handleInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };
  const handleInputDal = (e) => {
    e.preventDefault();
    setInputDal(e.target.value);
  };
  const handleInputAl = (e) => {
    e.preventDefault();
    setInputAl(e.target.value);
  };

  const showAll = (check) => {
    if (check) {
      document.getElementById("btn-mostra").disabled = true;
      document.getElementById("input-dal").disabled = true;
      document.getElementById("input-al").disabled = true;
      dispatch(searchSoci());
    } else {
      document.getElementById("btn-mostra").disabled = false;
      document.getElementById("input-dal").disabled = false;
      document.getElementById("input-al").disabled = false;
      dispatch(fetchDataSelected(inputDal, inputAl));
    }
  };

  const renderListSoci = () => {
    return (
      arrayListSoci
        // eslint-disable-next-line array-callback-return
        .filter((f) => {
          if (input === "") {
            return f;
          } else if (
            f.name.toLowerCase().includes(input.toLowerCase()) ||
            f.surname.toLowerCase().includes(input.toLowerCase()) ||
            f.dataRicheista.toLowerCase().includes(input.toLowerCase()) ||
            f.idCard.toLowerCase().includes(input.toLowerCase()) ||
            f.telefono.toLowerCase().includes(input.toLowerCase())
          ) {
            return f;
          }
        })
        .map((item) => {
          return <SocioCard socio={item} />;
        })
    );
  };
  const handleMostra = () => {
    dispatch(fetchDataSelected(inputDal, inputAl));
  };

  return (
    <>
      <div className="head-bar-ts" style={{ zIndex: 5 }}>
        <Link to="/admin">Admin</Link>

        <span>
          <Link to="/logout" className="">
            <FiIcons.FiLogOut />
          </Link>
        </span>
      </div>
      <div className="option-ts">
        {/* input Search */}
        <div class="input-group input-group-sm me-1 w-25 box-search">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <BiIcons.BiSearchAlt />
          </span>
          <input
            type="text"
            class="form-control"
            onChange={handleInput}
            value={input}
            // aria-label="Sizing example input"
            // aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <div class="form-check ms-1">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="invalidCheck2"
            name="invalidCheck2"
            checked={check}
            onChange={() => (setCheck(!check), showAll(!check))}
            required
          />
          <label class="form-check-label" for="invalidCheck2">
            Mostra tutti
          </label>
        </div>
        {check ? (
          <button type="button" class="btn btn-primary ms-4" id="btn-va" disabled="true">
            Verbale Ammissione
          </button>
        ) : (
          <Link
            className="link-pdf"
            to={{
              pathname: `/admin/pdf`,
              state: {
                dal: inputDal,
                al: inputAl,
              },
            }}
          >
            <button type="button" class="btn btn-primary ms-4">
              Verbale Ammissione
            </button>
          </Link>
        )}

        <div class="input-group input-group-sm me-1 ms-1 w-25">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Dal
          </span>
          <input
            id="input-dal"
            type="text"
            class="form-control"
            onChange={handleInputDal}
            value={inputDal}
            placeholder="gg-mm-aaaa Es. 17-08-2022 "
            // aria-label="Sizing example input"
            // aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <div class="input-group input-group-sm me-1 ms-1 w-25">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Al
          </span>
          <input
            id="input-al"
            type="text"
            class="form-control"
            onChange={handleInputAl}
            value={inputAl}
            placeholder="gg-mm-aaaa Es. 17-11-2022"
            // aria-label="Sizing example input"
            // aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary ms-4"
          onClick={handleMostra}
          id="btn-mostra"
        >
          Mostra <BiIcons.BiSearchAlt />
        </button>

        {/* <DataPikers fetchFunctions={fetchDataSelected} /> */}
      </div>
      <div className="admin-ts">
        {/* <table class="table table-bordered"> */}
        <table className="table table-bordered">
          <thead className="table-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nome</th>
              <th scope="col">Cognome</th>

              <th scope="col">N. ID Card</th>

              <th scope="col">Data Reg.</th>

              <th scope="col">Cellulare</th>
            </tr>
          </thead>
          <tbody>{renderListSoci()}</tbody>
        </table>
      </div>
    </>
  );
}

export default Admin;
