const FETCH_DATA_START = "FETCH_DATA_START";
const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
const FETCH_DATA_FAIL = "FETCH_DATA_FAIL";

export const fetchData = (startDate) => {
  return async (dispatch) => {
    dispatch(fetchDataStart());
    try {
      // end
      dispatch(fetchDataSuccess(startDate));
    } catch (error) {
      dispatch(fetchDataFail(error));
    }
  };
};

export const fetchDataStart = () => {
  return {
    type: FETCH_DATA_START,
  };
};

export const fetchDataSuccess = (startDate) => {
  return {
    type: FETCH_DATA_SUCCESS,
    startDate,
  };
};

export const fetchDataFail = (error) => {
  return {
    type: FETCH_DATA_FAIL,
    error: error,
  };
};

export { FETCH_DATA_START, FETCH_DATA_SUCCESS, FETCH_DATA_FAIL };
