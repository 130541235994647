import { getAuth, onAuthStateChanged } from "firebase/auth";

export const stateChange = () => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      //utente loggato
    } else {
      console.log("utente non loggato");
    }
  });
};
