import React from "react";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  {
    title: "Lista Crediti",
    path: "/lista-crediti",
    icon: <BsIcons.BsPersonLinesFill />,
    cName: "nav-text",
  },
  {
    title: "Report",
    path: "/report",
    icon: <MdIcons.MdOutlineQueryStats />,
    cName: "nav-text",
  },
  {
    title: "Impostazioni Voci",
    path: "/voice-setting",
    icon: <RiIcons.RiChatSettingsFill />,
    cName: "nav-text",
  },
  {
    title: "Help",
    path: "/help",
    icon: <MdIcons.MdOutlineLiveHelp />,
    cName: "nav-text",
  },
  {
    title: "Contattaci",
    path: "/contactform",
    icon: <RiIcons.RiMailSendFill />,
    cName: "nav-text",
  },
];
